import { scvRefDefault } from "src/core/service/deposits-api/DepositsApi.types";

export const numberMapper: Record<number, string> = {
  2: "second",
  3: "third",
  4: "fourth",
  5: "fifth",
  6: "sixth",
  7: "seventh",
  8: "eighth",
  9: "ninth",
  10: "tenth",
};

export const reArrangeListItem = (reArrangedItem: string, list: scvRefDefault[]) => {
  const itemObj = list?.find((item) => item.name === reArrangedItem);
  const newList = list?.filter((item) => item.name !== reArrangedItem);
  if (itemObj) {
    newList?.unshift(itemObj);
  }
  return newList;
};

export const getFilteredOptions = (
  currentCode: string,
  usedOptions: string[],
  lists: scvRefDefault[]
) => {
  const addExistingCode = usedOptions?.filter((codes: string) => codes !== currentCode);
  const filteredLists = lists.filter((item) => !addExistingCode.includes(item?.name));
  return filteredLists;
};
