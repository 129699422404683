import { UseFormSetError } from "react-hook-form";
import { scvRefDefault } from "src/core/service/deposits-api/DepositsApi.types";
import { dateFormatReverse } from "src/core/utils";
import { FormValues } from "./AboutYou.types";

export const defaultAboutYouData = {
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  countryOfBirth: "",
  nationalities: [{ value: "" }],
  taxResidencies: [{ taxCountryCode: "GB", number: "NA" }],
};

export const numberMapper: Record<number, string> = {
  2: "second",
  3: "third",
  4: "fourth",
  5: "fifth",
  6: "sixth",
  7: "seventh",
  8: "eighth",
  9: "ninth",
  10: "tenth",
};

export const formatDate = (date: string) => {
  return date?.split("/")?.reverse()?.join("-") ?? "";
};

export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
};

export const nameValidate = (value: string | null, message: string) => {
  let msg: string | boolean = true;
  value?.split("").forEach((item) => {
    if (!(["'", "-", " "].includes(item) || /^[A-Za-z]+$/.test(item))) {
      msg = message;
    }
  });
  return msg;
};

export const reArrangeListItem = (reArrangedItem: string, list: scvRefDefault[]) => {
  const itemObj = list?.find((item) => item.name === reArrangedItem);
  const newList = list?.filter((item) => item.name !== reArrangedItem);
  if (itemObj) {
    newList?.unshift(itemObj);
  }
  return newList;
};

export const setAboutYouFieldError = (
  data: {
    field: string;
    reason: string;
  },
  setError: UseFormSetError<FormValues>
) => {
  const { field, reason } = data;
  if (field.includes("nationalities") || field.includes("taxResidencies")) {
    if (field.includes("nationalities")) {
      const num = field.match(/\d/g);
      if (num) {
        setError(`nationalities.${+num}.value`, { message: reason });
      }
    }
    if (field.includes("taxResidencies")) {
      if (field.includes("number")) {
        const num = field.match(/\d/g);
        if (num && +num > 0) {
          setError(`taxResidencies.${+num - 1}.number`, { message: reason });
        }
      }
      if (field.includes("taxCountryCode")) {
        const num = field.match(/\d/g);
        if (num && +num > 0) {
          setError(`taxResidencies.${+num - 1}.taxCountryCode`, { message: reason });
        }
      }
    }
  } else {
    setError(field as keyof FormValues, { message: reason });
  }
};

export const getAboutYouData = (taxStatus: string, values: FormValues) => {
  const { dateOfBirth, nationalities, taxResidencies } = values;
  const formattedDate = formatDate(dateOfBirth);
  const data = {
    ...values,
    dateOfBirth: formattedDate,
    nationalities: nationalities?.map((item) => item?.value),
    taxResidencies:
      taxStatus === "0"
        ? defaultAboutYouData?.taxResidencies
        : [...defaultAboutYouData?.taxResidencies, ...taxResidencies],
  };
  return data;
};

export const resetDefaultValues = (aboutYouSection: any) => {
  const values: FormValues = {
    ...aboutYouSection,
    dateOfBirth: dateFormatReverse(aboutYouSection?.dateOfBirth),
    taxResidencies: aboutYouSection?.taxResidencies.slice(1), // we are not including the first that is default "GB", number: ""
    nationalities: aboutYouSection?.nationalities?.map((item: any) => {
      if (typeof item === "object") {
        return item;
      } else {
        return { value: item };
      }
    }),
  };
  return values;
};

export const getFilteredOptions = (
  currentCode: string,
  usedOptions: string[],
  lists: scvRefDefault[]
) => {
  const addExistingCode = usedOptions?.filter((codes: string) => codes !== currentCode);
  const filteredLists = lists.filter((item) => !addExistingCode.includes(item?.name));
  return filteredLists;
};
