import { InputGroup, InputRightElement, Select } from "@chakra-ui/react";
import { Button, ChevronRightIcon, ErrorIcon, Heading, StepStatus, Text, } from "@allica/ui-react";
import { FormGroup } from "src/components/input-set/FormGroup";
import MaskedInput from "src/components/masked-input/MaskedInput";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { FormValues } from "./AboutYou.types";
import TaxResidency from "./TaxResidency/TaxResidency";
import Nationality from "./Nationality/Nationality";
import { useEffect, useState } from "react";
import { usePersonalContext } from "../../context/PersonalContext";
import { DepositsAPI } from "src/core/service";
import { Stage } from "../../PersonalSavings.types";
import { useStore } from "src/core/store/StoreContext";
import {
    defaultAboutYouData,
    getAboutYouData,
    nameValidate,
    reArrangeListItem,
    resetDefaultValues,
    setAboutYouFieldError,
} from "./AboutYou.utils";
import { BaseInput } from "src/components/input/default/Input";
import { ConfirmationOfPayeeResponse } from "src/core/service/confirmation-of-payee-api/ConfirmationOfPayeeApi.types";
import { compareIndividualsNames, dateValidate } from "src/core/utils";

const AboutYou = () => {
    const referenceData = useStore();
    const formMethods = useForm<FormValues>({
        defaultValues: { ...defaultAboutYouData, nationalities: [{ value: "" }] },
    });
    const countries = reArrangeListItem("GB", referenceData?.countries);
    const titles = referenceData?.individualTitle;
    const {
        stepperConfig,
        personalSavingData,
        setCurrentStage,
        setStepperConfig,
        setPersonalSavingData,
        setShowGenericError,
    } = usePersonalContext();
    const {
        register,
        handleSubmit,
        setError,
        reset,
        getValues,
        formState: { errors },
    } = formMethods;

    const {
        status: aboutYouStatus,
        request: saveAboutYouData,
        error: aboutYouError,
    } = DepositsAPI(`applications/individuals/${personalSavingData?.applicationID}/about-you`);

    const [taxStatus, setTaxStatus] = useState("0");
    const [showAddNationality, setShowAddNationality] = useState(false);

    const onSubmit: SubmitHandler<FormValues> = (values) => {
        setShowGenericError(false);
        const payload = getAboutYouData(taxStatus, values);
        if (personalSavingData?.applicationID) {
            saveAboutYouData({
                method: "PATCH",
                body: JSON.stringify(payload),
            });
        }
    };

    useEffect(() => {
        const aboutYouSection =
            personalSavingData?.individualApplicationSections?.aboutYouSection || defaultAboutYouData;
        const newValues = resetDefaultValues(aboutYouSection);
        let nationalities = newValues?.nationalities;
        if (newValues?.taxResidencies?.length >= 1) {
            setTaxStatus("1");
        }
        if (newValues?.nationalities?.length > 1) {
            setShowAddNationality(true);
        }
        if (newValues?.nationalities && newValues?.nationalities?.length < 1) {
            nationalities = [{ value: "" }];
        }
        reset({ ...newValues, nationalities: nationalities });
    }, [personalSavingData?.individualApplicationSections?.aboutYouSection]);

    useEffect(() => {
        if (aboutYouStatus.success) {
            const newPersonalSavingData = { ...personalSavingData };
            const values = getValues();
            const data = getAboutYouData(taxStatus, values);
            newPersonalSavingData.individualApplicationSections.aboutYouSection = { ...data };
            setPersonalSavingData(newPersonalSavingData);

            const newState = { ...stepperConfig };

            if (personalSavingData?.individualApplicationSections?.accountSection?.name) {
                const nameMatchesAccount = compareIndividualsNames({
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    accountName: personalSavingData?.individualApplicationSections?.accountSection?.name,
                });

                if (!nameMatchesAccount) {
                    newState[Stage.ACCOUNT].status = StepStatus.ALERT;
                    setPersonalSavingData({
                        ...personalSavingData,
                        individualApplicationSections: {
                            ...personalSavingData.individualApplicationSections,
                            accountSection: {
                                name: "",
                                sortCode: "",
                                accountNumber: "",
                                copResponse: {} as ConfirmationOfPayeeResponse,
                            },
                        },
                    });
                }
            }

            newState[Stage.ABOUT_YOU].status = StepStatus.COMPLETE;
            if (newState[Stage.ADDRESS].status !== StepStatus.COMPLETE) {
                newState[Stage.ADDRESS].status = StepStatus.INCOMPLETE;
            }
            setStepperConfig(newState);
            setCurrentStage(Stage.ADDRESS);
        }
    }, [aboutYouStatus.success]);

    useEffect(() => {
        if (aboutYouStatus.error) {
            if (aboutYouError.code === "VALIDATION_ERROR") {
                aboutYouError?.errors?.forEach((data) => {
                    setAboutYouFieldError(data, setError);
                });
            } else {
                setShowGenericError(true);
            }
        }
    }, [aboutYouStatus.error]);

    const { onChange: dateOfBirthRefOnchange, ...restReg } = register("dateOfBirth", {
        required: "Please enter a date of birth",
        validate: dateValidate,
    });

    return (
        <>
            <Heading size="h1" as="h1" color="$text-01" mb="1.6rem" mt="6.4rem">
                About you
            </Heading>
            <Text textStyle="body-03" color="$text-03" mb="6.4rem">
                Please make sure the details below match your official identification such as your passport
                or drivers license.
            </Text>

            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <FormGroup label="Title" mb="3.2rem" isRequired error={errors?.title?.message}>
                    <Select
                        {...register("title", { required: "Please select a title" })}
                        width="24rem"
                        placeholder="Please select"
                    >
                        {titles?.map((item) => (
                            <option key={item?.name} value={item?.name}>
                                {item?.description}
                            </option>
                        ))}
                    </Select>
                </FormGroup>

                <FormGroup label="First name" isRequired mb="3.2rem" error={errors?.firstName?.message}>
                    <BaseInput
                        {...register("firstName", {
                            required: "Please enter your first name",
                            maxLength: { value: 50, message: "Please enter valid first name" },
                            setValueAs: (value) => value?.trim(),
                            validate: {
                                nameValidate: (value) => nameValidate(value, "Please enter valid first name"),
                            },
                        })}
                        isInvalid={!!errors?.firstName}
                    />
                </FormGroup>

                <FormGroup label="Middle name" mb="3.2rem" error={errors?.middleName?.message}>
                    <BaseInput
                        isInvalid={!!errors?.middleName}
                        {...register("middleName", {
                            maxLength: { value: 50, message: "Please enter valid middle name" },
                            setValueAs: (value) => value?.trim(),
                            validate: {
                                nameValidate: (value) => nameValidate(value, "Please enter valid middle name"),
                            },
                        })}
                    />
                </FormGroup>

                <FormGroup label="Last name" mb="3.2rem" isRequired error={errors?.lastName?.message}>
                    <BaseInput
                        isInvalid={!!errors?.lastName}
                        {...register("lastName", {
                            required: "Please enter your last name",
                            maxLength: { value: 50, message: "Please enter valid last name" },
                            setValueAs: (value) => value?.trim(),
                            validate: {
                                nameValidate: (value) => nameValidate(value, "Please enter valid last name"),
                            },
                        })}
                    />
                </FormGroup>

                <FormGroup
                    label="Date of birth"
                    mb="3.2rem"
                    isRequired
                    info={
                        <>
                            <Text>All applicants must be over 18 years old</Text>
                            <Text>DD/MM/YYYY</Text>
                        </>
                    }
                    error={errors?.dateOfBirth?.message}
                >
                    <InputGroup width="24rem">
                        <MaskedInput
                            maskedPattern="**/**/****"
                            maskedChar="*"
                            validate={(value: string) => /^\d+$/?.test(value)}
                            onChangeHandler={(e) => {
                                dateOfBirthRefOnchange(e);
                            }}
                            {...restReg}
                        />
                        <InputRightElement>
                            {!!errors?.dateOfBirth && <ErrorIcon color="$danger-01" />}
                        </InputRightElement>
                    </InputGroup>
                </FormGroup>

                <FormGroup
                    label="Country of birth"
                    isRequired
                    mb="3.2rem"
                    error={errors?.countryOfBirth?.message}
                >
                    <Select
                        {...register("countryOfBirth", { required: "Please select a country of birth" })}
                        placeholder="Please select"
                    >
                        {countries?.map((item) => (
                            <option key={item?.name} value={item?.name}>
                                {item?.description}
                            </option>
                        ))}
                    </Select>
                </FormGroup>

                <FormProvider {...formMethods}>
                    <Nationality
                        showAddNationality={showAddNationality}
                        setShowAddNationality={setShowAddNationality}
                    />
                </FormProvider>

                <FormProvider {...formMethods}>
                    <TaxResidency setTaxStatus={setTaxStatus} taxStatus={taxStatus} />
                </FormProvider>

                <Button
                    isLoading={aboutYouStatus?.loading}
                    loadingText="Save and continue"
                    spinnerPlacement="end"
                    type="submit"
                    mt="8rem"
                    float="right"
                    padding="2.4rem 3.2rem"
                    rightIcon={<ChevronRightIcon />}
                >
                    Save and continue
                </Button>
            </form>
        </>
    );
};

export default AboutYou;
