import { useEffect, useState } from "react";
import { Button, getHeadingStyle, Heading, PlusIcon, getTextStyle } from "@allica/ui-react";

import { Card } from "@chakra-ui/card";
import { Box, Flex } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/react";
import { FormGroup } from "src/components/input-set/FormGroup";

import { useStore } from "src/core/store/StoreContext";
import { useFormContext } from "react-hook-form";
import { BinIcon } from "src/components/icon";
import { BaseInput } from "src/components/input/default/Input";
import { FormValues, TaxResidencyCardProps } from "./TaxResidency.types";
import { getFilteredOptions } from "./TaxResidency.utils";

export const TaxResidencyCard = ({
  handleAddTaxResidency,
  handleDeleteTaxInfo,
  taxResidencyNumber,
  taxResidencies,
  handleCountryChange,
  changedCountry,
}: TaxResidencyCardProps) => {
  const referenceData = useStore();
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();

  const [countriesOptions, setCountriesOptions] = useState(referenceData.countries);
  const data = getValues();
  const currentCountryCode = getValues(`taxResidencies.${taxResidencyNumber}.taxCountryCode`);

  useEffect(() => {
    const usedCountryCodes = data?.taxResidencies?.map((item) => item.taxCountryCode);
    usedCountryCodes.push("GB"); //Adding United kingdom in used countryCode as default
    const filteredCounties = getFilteredOptions(
      currentCountryCode,
      usedCountryCodes,
      referenceData?.countries
    );
    setCountriesOptions(filteredCounties);
  }, [changedCountry, taxResidencies]);

  return (
    <>
      <Card
        variant="outline"
        px="3.2rem"
        mt={taxResidencyNumber === 0 ? "2.4rem" : "0.2rem"}
        bgColor="$ui-01"
      >
        <Flex justifyContent="space-between" mt="2.4rem" mb="4rem">
          <Heading sx={getHeadingStyle("heading-05")} color="$interactive-02">
            Tax Residency {taxResidencyNumber + 2}
          </Heading>
          <Button
            variant="text"
            color="$danger-01"
            sx={getTextStyle("body-03")}
            onClick={() => handleDeleteTaxInfo(taxResidencyNumber)}
            leftIcon={<BinIcon boxSize="2rem" />}
            iconSpacing="0.8rem"
          >
            Delete
          </Button>
        </Flex>

        <FormGroup
          label="Country"
          isRequired
          mb="3.2rem"
          error={errors?.taxResidencies?.[taxResidencyNumber]?.taxCountryCode?.message}
        >
          <Select
            placeholder="Please select"
            {...register(`taxResidencies.${taxResidencyNumber}.taxCountryCode`, {
              required: "Please select a country from the list",
              onChange: (e: Event) => {
                handleCountryChange(e);
              },
            })}
          >
            {countriesOptions?.map((country) => (
              <option key={country?.name} value={country?.name}>
                {country?.description}
              </option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup
          label="Tax identification number"
          mb="4rem"
          isRequired
          error={errors?.taxResidencies?.[taxResidencyNumber]?.number?.message}
        >
          <BaseInput
            isInvalid={!!errors?.taxResidencies?.[taxResidencyNumber]?.number}
            {...register(`taxResidencies.${taxResidencyNumber}.number`, {
              required: "Please add a valid tax identification number",
              setValueAs: (value) => value?.trim(),
            })}
          />
        </FormGroup>

        {taxResidencyNumber + 1 !== 9 && taxResidencies?.length === taxResidencyNumber + 1 && (
          <Box mb="4rem">
            <Button
              variant="text"
              leftIcon={<PlusIcon />}
              onClick={() => handleAddTaxResidency(taxResidencyNumber)}
              iconSpacing="0.8rem"
            >
              Add tax residency
            </Button>
          </Box>
        )}
      </Card>
    </>
  );
};
